// Spirit-crafted Classes, for specific use cases

@import './color.scss';
@import './custom.scss';

html {
  font-size: 14px;
}

body {
  background-color: black;
}
svg {
  pointer-events: none;
} // so react-icon SVG files can be added to buttons and clicked

.social-media-icon {
  display: inline-block;
  border-radius: 50%;
  padding: 12px;
  background-color: white;
}

.social-media-icon-dm {
  display: inline-block;
  border-radius: 50%;
  padding: 12px;
  background-color: $dm-bg;
}

.shipping-address-select:hover {
}
//--- Product Items -related
.product-status-dot {
  height: 14px;
  width: 14px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.product-status-dot-green {
  background-color: #6c8f34;
}
.product-status-dot-yellow {
  background-color: #e0c643;
}
.product-status-dot-red {
  background-color: #953324;
}
//=====

.fixed_search_bar {
  @media (max-width: 992px) {
    position: fixed !important;
    top: 76px !important;
    left: 0px !important;
    z-index: 9999999999;
  }
}

.expanded_search_bar,
.expanded_search_result_data {
  position: fixed !important;
  left: 25% !important;
  z-index: 9999999999;
  width: 60% !important;
}

.expanded_search_bar {
  // top: 4% !important;
}

.expanded_search_bar_dm {
  border-color: $light !important;
}

.headernavbar_mobile_buttons {
  @extend .py-0;
  @extend .my-0;
  @extend .shadow-none;
  @extend .btn;
  @extend .btn-primary;
  @extend .no_wrap;
  @extend .border-white;
}

.headersearchbar_button {
  border-color: $primary;
  &:hover,
  &:active,
  &:focus {
    @extend .shadow-none;
  }
  &:hover {
    border-color: $primary !important;
  }
  &:hover,
  &:focus {
    background-color: $white !important;
  }
  &:active {
    background-color: $secondary !important;
  }
}
.headersearchbar_input {
  border-color: $primary !important;

  &:active,
  &:focus {
    border-color: $primary !important;
  }
}

.no_caret {
  :after {
    color: transparent;
  }
}

.button_text_bottom {
  display: inline-flex;
  align-items: flex-end;
  margin-top: 2px;
  box-shadow: none !important;
}

.product_lists_nav_bar {
  height: 35px;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.product_lists_nav_bar_mobile {
  height: 35px;
}

.hamburger-menu-button {
  white-space: nowrap;
  width: 75px;
}

.contact-us {
  white-space: nowrap;
  min-width: 75px;
}
//=====

//--- HeaderSearchBar results display

.search-result-data {
  // margin-top: 0px;
  padding-top: 1px;
  padding-right: 0 !important;
  margin-right: 0 !important;
  width: 225px;
  height: auto;
  background-color: white;
  z-index: 9999999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  @media (max-width: 992px) {
    width: 96% !important;
    margin: 1px 1px 1px 4px !important;
    position: fixed !important;
  }

  .data-item {
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }

  .data-item-hover {
    :hover {
      background-color: rgb(233, 233, 233);
    }
    cursor: pointer;
  }

  .search-thumbnail {
    max-width: 40px;
    max-height: 40px;
    margin: 2%;
  }

  // &::-webkit-scrollbar {
  //     display: none;
  // }
}

.search-bar-input {
  @media (max-width: 992px) {
    width: 98% !important;
    margin: 1px 1px 1px 4px !important;
    position: relative;
  }
}

.no-focus-dropdown-button {
  :focus {
    box-shadow: none !important;
  }
}

//=====

//--- loading spinner gif
.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: $white;
}
//=====

//--- pagination
.pagination {
  padding: 0;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  text-align: center;
}

.pagination .page-item {
  display: inline-block;
  margin: 0 3px;
}

.pagination li {
  display: block;
}

.pagination .page-item .page-link {
  color: $primary;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: $tertiary;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  // border-radius: 50%;
  // -webkit-border-radius: 50%;
  // -moz-border-radius: 50%;
  // -ms-border-radius: 50%;
  // -o-border-radius: 50%;
}

.pagination .page-item.active .page-link {
  background-color: $primary;
  color: $white;
}

.pagination .enabled.page-item:hover .page-link {
  background-color: $primary;
  color: $white;
}

.pagination .button-disabled.page-item .page-link {
  background-color: $light;
  color: $white;
}

.dm-pagination.pagination .page-item .page-link {
  color: $dm-text;
  background: $dm-bg;
}

.dm-pagination.pagination .page-item.active .page-link {
  background-color: $dm-card-bg;
  color: $white;
}

.dm-pagination.pagination .enabled.page-item:hover .page-link {
  background-color: $dm-card-bg;
  color: $white;
}

.dm-pagination.pagination .button-disabled.page-item .page-link {
  background-color: $dm-bg;
  color: $light;
}
//=====

//--- shoplist filters
.filter_sel_box {
  width: 150px;
  max-width: 32vw;
  padding: 0 12px;
  font-size: 16px;
  height: 35px;
  -webkit-appearance: none;
  border: 1px solid;
  border-color: $secondary;

  &:hover {
    border-color: $info;
  }
}

ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.product_view_position {
  margin-top: 35px;
}

.product_filter_reset_position {
  margin-top: 35px;
}

//=====

//--- Site container
.page_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  max-width: 1500px;
  margin: 0 auto;
}

.body_size {
  flex: 1;
}

//=====

//--- Footer
.site-footer {
  display: flex;
  justify-content: space-between;
  text-align: right;
  width: 100vw !important;

  @media (max-width: 576px) {
    justify-content: center;

    p {
      text-align: center;
    }
    div {
      align-items: center;
      margin: 1px 5px !important;
    }
  }
}

//=====

//--- space-between items
.vertical_flex_space_between_container {
  display: flex;
  flex-direction: column;
}

.horizontal_flex_space_between_container {
  display: flex;
  flex-direction: row;
}

.flex_space_between_child_1 {
  flex: 1;
}
.flex_space_between_child_2 {
  flex: 2;
}
.flex_space_between_child_half {
  flex: 0.5;
}

//=====

// more width classes

.w_10 {
  width: 10% !important;
}
.w_20 {
  width: 20% !important;
}
.w_50 {
  width: 50% !important;
}
.w_60 {
  width: 60% !important;
}
.w_75 {
  width: 75% !important;
}

//=====
.cursor_pointer {
  cursor: pointer;
}
//--- Product Filter
.filter-box {
  overflow-y: scroll;
  max-height: 300px;
}

//=====

//--- Product Details

.carousel_thumbnail {
  max-width: 40px;
  height: auto !important;
  max-height: 40px;
  margin-left: auto !important;
  margin-right: auto !important;
  cursor: pointer;
}

//=====

//-----iFrame ---

.iframe_container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive_iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
//=====

//----- My Account and Login / Register Pages ---

// ..Address Form in Profile, Register
.register_card {
  width: 75% !important;

  @media (max-width: 992px) {
    width: 92% !important;
  }
}

.profile_information_form {
}
.register_form {
  padding: 0.5rem 1rem !important;
  .row {
    margin-left: 0 !important;
    max-width: 100% !important;
  }
  & input,
  select {
    // padding: 0.25rem 0.5rem !important;
    // margin-bottom: 0.25rem !important;
  }
}
.preferences_form {
  & input {
    font-size: large !important;
  }
  & label {
    font-size: large !important;
  }
}
// ..Sidebar in Profile

.document_links {
  & a,
  span {
  }
}

.button_radio {
  padding: 0;
  margin: 0;
  color: $text-muted;

  & input[type='radio'] {
    visibility: hidden;
    height: 0;
    width: 0;
    // background-color: $light;
    margin: 0;
  }
  & input[type='radio'] + label {
    // background-color: $light;
    margin: 0;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    // border-color: $gray-400;
    border-radius: 5px;
    cursor: pointer;
  }
  & input[type='radio']:checked + label {
    background-color: $secondary;
    // border-color: $dark;
    color: white;
  }
  & input[type='radio']:hover + label {
    background-color: $secondary;
    // border-color: $dark;
    color: white;
  }
}

.button_radio_yes_no {
  & input[type='radio'] + label {
    min-width: 60px;
    text-align: center;
  }
}

// Checkout
.textarea {
  height: 100px !important;
}

//  Colors used in a few places, but not worth plugging into bootstrap

.excel_green {
  color: #1d6f42 !important; //https://www.schemecolor.com/microsoft-excel-logo-color.php
}
.pdf_red {
  color: #f40f02 !important; //https://www.schemecolor.com/adobe-acrobat-logo-colors.php
}

.input-quantity-control {
  min-height: 50px;
}
//=====

// Floating Form  ----

// .floating_form_outer_div {
//   & label {
//   }
//   & input {
//   }
//   & small {
//   }
// }

// .custom_floating_input_area {
//   margin-top: 0.5em !important; // NOTE: this is a weird way to add space between, but with the input, label, and hint all working against each other, this is the best way I could figure out.
// }
//=====

// orders_accordion columns / items

.orders_accordion_image {
  max-width: 15% !important;
  min-width: 10% !important;
}
.orders_accordion_product_name {
  max-width: 500px !important;

  @media (max-width: 992px) {
    width: 100% !important;
  }
}

.hot-deals div {
  background-image: url('https://d29j2csl3b3dix.cloudfront.net/_images/flash_deals.jpg');
  background-repeat: no-repeat;
}

//=====

// ..icon styles

.react-icons {
  transform: scale(1.75) !important;
  min-width: 10px !important;
  padding: 2px 0 !important;
}
//=====

// Banner mockup styles
.hero_img img {
  position: absolute;
  top: 0px;
  right: -200px;
  z-index: 99;
}

.hero_img_mobile img {
  position: absolute;
  top: 0px;
  right: -70px;
  z-index: 99;
}

#banner_one {
  background-image: url('../assets/img/mockup/4017772.jpg');
  padding: 200px 0;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

#banner_one_mobile {
  background-image: url('../assets/img/mockup/4017772.jpg');
  padding: 100px 0px;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.banner_text_one h1 {
  font-size: 150px;
  text-transform: uppercase;
  font-weight: 100;
  line-height: 150px;
}

.banner_text_one h3 {
  text-transform: uppercase;
  font-weight: 500;
  color: #f3f3f3;
}

.banner_text_one h1 span {
  display: block;
  color: #fff;
  font-weight: 700;
}

.btn_md {
  padding: 16px 35px;
}

.product_variation_one_boxed {
  margin-bottom: 30px;
}

.row .col-lg-4 .product_variation_one_boxed:last-child {
  margin-bottom: 0;
}

.product_variation_one_boxed {
  position: relative;
  overflow: hidden;
}

.product_variation_one_boxed img {
  width: 100%;
}

.product_var_one_text_center {
  position: absolute;
  left: 40px;
  bottom: 0;
  padding: 20px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.product_var_one_text {
  position: absolute;
  // left: 20px;
  top: 50%;
  padding: 20px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

// .product_var_one_text h2,
// .product_var_one_text_center h2 {
//   padding: 5px 0;
//   text-transform: uppercase;
//   font-weight: 400;
// }

// .product_var_one_text h4,
// .product_var_one_text_center h4 {
//   text-transform: uppercase;
// }

// .product_var_one_text a,
// .product_var_one_text_center a {
//   margin-top: 20px;
// }

.color_one {
  color: var(--main-theme-color);
}

.img-zoom-hover img {
  /* transform-origin: 65% 75%; */
  transition: transform 1s, filter 0.5s ease-out;
}

.img-zoom-hover:hover img {
  transform: scale(1.1) rotate(1deg);
}

#special_offer_one {
  background-image: url('https://d29j2csl3b3dix.cloudfront.net/_images/prototype/special-offer.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 115px 0;
  background-size: contain;
}

//=====

///*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}
//=====

// Input File Select

.custom_file_input_disabled {
  background-color: $light;
}
.custom_file_input_enabled {
  background-color: $gray-400;
  cursor: pointer;
}

.remove_right_border {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.remove_left_border {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.react-datepicker__time-list-item--disabled {
  display: none !important;
} // from https://github.com/Hacker0x01/react-datepicker/issues/2056
.custom_date_picker {
  padding: 5px 5px 5px 10px;
}
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 999999999999 !important;
}

.dm-bg {
  background-color: $dm-bg !important;
}

.dm-body-bg {
  background-color: $dm-body-bg !important;
}

.dm-card-bg {
  background-color: $dm-card-bg !important;
}

.dm-text {
  color: $dm-text !important;
}

.dm-text-bg {
  color: $dm-bg !important;
}

.dm-body-text-bg {
  color: $dm-body-bg !important;
}

.dm-proline-primary-bg {
  background-color: $dm-proline-primary !important;
}

.dm-proline-primary-text {
  color: $dm-proline-primary !important;
}

.rw-fs-big-1 {
  font-size: 80px;
}

.rw-fs-big-2 {
  font-size: 70px;
}

.rw-fs-big-3 {
  font-size: 60px;
}

.dm-rw-carousel .carousel .carousel-control-prev-icon {
  background-color: black !important;
}

.dm-rw-carousel .carousel .carousel-control-next-icon {
  background-color: black !important;
}

.ishot-header-bg {
  background-image: url('https://d29j2csl3b3dix.cloudfront.net/_images/ishot_tubes_bg_cropped_dk.png');
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

.horizontal-ellipsis-container {
  text-align: center;
}

.horizontal-ellipsis {
  display: inline-block;
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  margin: 30px 0;
}

.horizontal-ellipsis span {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 15px;
}

.bg-white {
  background-color: white;
}

.bg-black {
  background-color: black;
}
